<template>
  <div>
    <PageHeader title="Shop">
      <div slot="buttons" class="tw-flex tw-flex-row tw-flex-wrap tw-gap-2">
        <select
          name="category"
          class="formulate-select sm:tw-h-auto sm:tw-w-auto tw-m-0"
          @change="fetchProducts({ params: { category: $event.target.value, page_size: 24 } })"
        >
          <option value="">Alle categorieën</option>
          <option v-for="category in categories" :key="category.id" :value="category.id">
            {{ category.name }}
          </option>
        </select>
        <button
          type="button"
          title="Winkelmandje"
          :disabled="loadingCart"
          class="btn btn-success tw-flex-grow"
          @click="openCart"
        >
          <i
            :class="[
              'fas tw-mr-1',
              loadingCart ? 'fa-spinner-third fa-spin' : 'fa-shopping-cart'
            ]"
          />
          Winkelmandje
        </button>
        <router-link
          :to="{ name: 'ShopOrders' }"
          title="Bestellingen"
          class="btn btn-default tw-flex-grow"
        >
          <i class="fas fa-list" />
          Bestellingen
        </router-link>
        <button
          v-if="collaborator.hasPerm('CREATE_MARKETING_SHOP_PRODUCT')"
          type="button"
          title="Nieuw product"
          :disabled="loadingCreationData"
          class="btn btn-primary tw-flex-grow"
          @click="showNewProductForm"
        >
          <i
            :class="[
              'fas tw-mr-1',
              loadingCreationData ? 'fa-spinner-third fa-spin' : 'fa-plus'
            ]"
          />
          Nieuw
        </button>
      </div>
    </PageHeader>

    <div class="tw-my-8 tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-4 xl:tw-grid-cols-6 tw-gap-4">
      <div
        v-for="product in products.results"
        :key="product.id"
        class="tw-px-4 tw-py-2 tw-bg-white tw-flex tw-flex-col"
      >
        <div class="tw-flex tw-flex-row tw-justify-between">
          <span>{{ product.category.name }}</span>
          <button
            v-if="product.is_business_card"
            type="button"
            title="Verwijderen"
            class="btn btn-xs btn-danger"
            @click="deleteBusinessCard(product.id)"
          >
            <i class="fas fa-trash" />
          </button>
        </div>
        <h4 class="tw-my-2">{{ product.name }}</h4>
        <p class="tw-flex-grow">{{ product.description }}</p>
        <div class="tw-my-2 tw-flex tw-flex-wrap tw-gap-2 tw-self-end tw-justify-end">
          <router-link
            v-if="product.is_business_card"
            :to="{ name: 'ShopBusinessCard', params: { productId: product.id } }"
            title="Vernieuw ontwerp"
            class="btn btn-xs btn-default tw-flex-grow"
          >
            <i class="far fa-file-plus" />
            Vernieuw ontwerp
          </router-link>
          <a
            :href="product.design_file"
            target="_blank"
            title="Ontwerp"
            class="btn btn-xs btn-warning tw-flex-grow"
          >
            <i class="fas fa-file-download" />
            Ontwerp
          </a>
          <button
            type="button"
            title="Bestellen"
            class="btn btn-xs btn-success tw-flex-grow"
            @click="addToCart({ product: product.id, count: 1 })"
          >
            <i class="fas fa-cart-plus" />
            Bestellen
          </button>
        </div>
      </div>
    </div>

    <nav class="tw-flex tw-flex-row tw-space-x-3 tw-justify-end tw-text-right">
      <button
        type="button"
        :disabled="!products.previous"
        class="link tw-font-semibold disabled:tw-text-gray-200"
        @click="fetchProducts({ url: products.previous, params: { page_size: 24 } })"
      >
        <i class="fa fa-chevron-left" /> Vorige
      </button>
      <button
        type="button"
        :disabled="!products.next"
        class="link tw-font-semibold disabled:tw-text-gray-200"
        @click="fetchProducts({ url: products.next, params: { page_size: 24 } })"
      >
        Volgende <i class="fa fa-chevron-right" />
      </button>
    </nav>

    <!-- !tw-overflow-visible to allow the dropdown to extend beyond the modal. -->
    <BaseModal
      ref="cart"
      title="Winkelmandje"
      max-width="tw-max-w-xl !tw-overflow-visible"
      content-padding="tw-p-6 md:tw-px-8"
    >
      <FormulateForm
        #default="{ isLoading }"
        v-model="values"
        name="cart"
        @submit="checkout"
      >
        <div v-if="cart.lines.length" class="tw-overflow-auto">
          <div
            v-for="(line, index) in cart.lines"
            :key="index"
            class="tw-py-2 tw-bg-white tw-flex justify-between tw-border-b-0.5"
          >
            <div class="tw-flex-grow">
              {{ line.product.category.name }}
              <h4 class="tw-my-2">{{ line.product.name }}</h4>
              {{ line.product.description }}
            </div>
            <div class="tw-flex tw-flex-col sm:tw-flex-row tw-gap-2 sm:tw-gap-8 tw-items-center">
              <div class="tw-flex-grow">
                <button
                  type="button"
                  :disabled="line.count === 1"
                  class="tw-my-1 tw-mr-2 tw-px-1 tw-rounded-md tw-bg-white tw-shadow-md disabled:tw-opacity-20"
                  @click="line.count = parseInt(line.count) - 1; updateOrderLine(line)"
                >
                  <i class="far fa-minus" />
                </button>
                {{ line.count }}
                <button
                  type="button"
                  class="tw-my-1 tw-ml-2 tw-px-1 tw-rounded-md tw-bg-white tw-shadow-md"
                  @click="line.count = parseInt(line.count) + 1; updateOrderLine(line)"
                >
                  <i class="far fa-plus" />
                </button>
              </div>
              <button
                type="button"
                title="Verwijderen"
                class="btn btn-danger btn-xs"
                @click="removeOrderLine(line)"
              >
                <i class="fas fa-trash" />
              </button>
            </div>
          </div>
        </div>
        <span v-else>De winkelwagen is leeg.</span>

        <div class="tw-mt-4 tw-flex tw-justify-between tw-gap-4 tw-items-start">
          <FormulateInput
            type="autocomplete"
            auto-complete-type="office"
            name="office"
            label="Leveren bij"
            placeholder="Selecteer kantoor"
            validation="required"
            :input-class="['tw-text-sm tw-h-8 tw-mt-0.5']"
            :label-class="['tw-text-xs']"
            outer-class="tw-m-0"
          />
          <!-- we add top margin to the button to align it with the input -->
          <button
            type="submit"
            :disabled="isLoading || !cart.lines.length"
            class="btn btn-success tw-mt-5"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-shopping-cart'
              ]"
            />
            Bestellen
          </button>
        </div>
      </FormulateForm>
    </BaseModal>
    <BaseModal ref="newProduct" title="Nieuw product">
      <FormulateForm
        #default="{ isLoading }"
        v-model="newProduct"
        name="createProduct"
        invalid-message="Gelieve de verplichte velden correct in te vullen."
        @submit="createNewProduct"
      >
        <FormulateInput
          type="text"
          name="name"
          label="Naam"
          placeholder="Naam van het product"
          validation="required:trim"
        />
        <FormulateInput
          type="select"
          name="category"
          label="Categorie"
          :options="categoryOptions"
          validation="required"
        />
        <FormulateInput
          type="select"
          name="office"
          label="Kantoor"
          :options="officeOptions"
          validation="required"
        />
        <transition name="fade" mode="out-in">
          <div v-if="newProduct.office">
            <FormulateInput
              type="select"
              name="supplier"
              label="Leverancier"
              :options="supplierOptions"
              validation="required"
            />
            <FormulateInput
              type="autocomplete"
              auto-complete-type="collaborator"
              :params="{ office: newProduct.office }"
              name="collaborator"
              label="Medewerker"
              placeholder="Selecteer medewerker"
              validation="required"
            />
          </div>
        </transition>
        <FormulateInput
          type="textarea"
          name="description"
          label="Beschrijving"
          placeholder="Beschrijving van het product"
        />
        <FormulateErrors />
        <div class="tw-mt-4 tw-flex tw-flex-row tw-gap-2 tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :input-class="['tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
          <FormulateInput
            type="button"
            :input-class="['tw-bg-error tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
            @click="hideNewProductForm"
          >
            <i class="fa fa-times tw-mr-2"/> Annuleren
          </FormulateInput>
        </div>
      </FormulateForm>
    </BaseModal>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'

import { mapGetters } from 'vuex'
import { successModal, errorModal, questionModal } from '@/modalMessages'
import {
  getCategories,
  getProducts,
  createOrderLine,
  updateOrderLine,
  removeOrderLine,
  getCart,
  checkout,
  fetchProductCreationData,
  createProduct,
  deleteProduct
} from '@/services/shop'

export default {
  name: 'Shop',
  components: {
    PageHeader
  },
  data () {
    return {
      values: {},
      products: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      categories: [],
      cart: {
        lines: []
      },
      loadingCart: false,
      creationData: {},
      loadingCreationData: false,
      newProduct: {}
    }
  },
  computed: {
    ...mapGetters('account', ['collaborator']),

    officeOptions () {
      return this.creationData.offices.map(({ display_name, id }) => {
        return { label: display_name, value: id }
      })
    },
    categoryOptions () {
      return this.creationData.categories.map(({ name, id }) => {
        return { label: name, value: id }
      })
    },
    supplierOptions () {
      const suppliers = this.creationData.suppliers[this.newProduct.office] || []
      return suppliers.map(({ name, id }) => {
        return { label: name, value: id }
      })
    }
  },
  created () {
    this.init()
  },
  methods: {
    setInitialOffice () {
      const primaryOfficeOfCollaborator = this.collaborator.tewerkstellingen.find(office => office.primair === true)
      if (primaryOfficeOfCollaborator) {
        const { kantoor, office_id } = primaryOfficeOfCollaborator
        this.$set(this.values, 'office', { display_name: kantoor, id: office_id })
      }
      return primaryOfficeOfCollaborator
    },
    async init () {
      return await Promise.all([
        this.fetchProducts(),
        this.fetchCategories(),
        this.setInitialOffice()
      ])
    },
    async fetchProducts (payload = { params: { page_size: 24 } }) {
      const response = await getProducts(payload)
      this.products = response.data
      return response
    },
    async fetchCategories () {
      // We cannot paginate in the select options, for now.
      const response = await getCategories({ page_size: 1000 })
      this.categories = response.data?.results
      return response
    },
    async addToCart (payload) {
      try {
        const response = await createOrderLine(payload)
        successModal('Succesvol toegevoegd aan winkelwagen')
        return response
      } catch (error) {
        errorModal('Kan artikel niet aan winkelwagen toevoegen, probeer het opnieuw.')
      }
    },
    async deleteBusinessCard (productId) {
      const result = await questionModal('Ben je zeker dat je dit visitekaartje wilt verwijderen?')
      if (!result.value) return
      try {
        this.loading = true
        await deleteProduct(productId)
        await this.fetchProducts()
      } catch (error) {
        errorModal('Kan visitekaartje niet verwijderen, probeer het opnieuw.')
      }
    },
    async fetchCart () {
      const response = await getCart()
      this.cart = response.data
      return response
    },
    async openCart () {
      try {
        this.loadingCart = true
        const response = await this.fetchCart()
        this.$refs.cart.show()
        return response
      } catch (error) {
        errorModal('Kan de winkelwagen niet openen, probeer het opnieuw.')
      } finally {
        this.loadingCart = false
      }
    },
    hideCart () {
      this.$refs.cart.hide()
    },
    async checkout ({ office }) {
      try {
        const response = await checkout({ deliver_to_id: office.id })
        this.hideCart()
        successModal('De bestelling is succesvol geplaatst')
        return response
      } catch (error) {
        errorModal('Kan niet afrekenen, probeer het opnieuw.')
      }
    },
    async updateOrderLine (orderline) {
      try {
        const response = await updateOrderLine(orderline.id, { count: orderline.count })
        successModal('Producthoeveelheid is bijgewerkt.')
        return response
      } catch (error) {
        errorModal('Kan producthoeveelheid niet bijwerken, probeer het opnieuw.')
      }
    },
    async removeOrderLine (orderline) {
      try {
        const response = await removeOrderLine(orderline.id)
        await this.fetchCart()
        return response
      } catch (error) {
        errorModal('Kan het product niet verwijderen, probeer het opnieuw.')
      }
    },
    async showNewProductForm () {
      try {
        this.loadingCreationData = true
        const response = await fetchProductCreationData()
        this.creationData = response.data
        this.$refs.newProduct.show()
        return response
      } catch (error) {
        errorModal('Kan de details van het maken van het product niet ophalen, probeer het nog eens.')
      } finally {
        this.loadingCreationData = false
      }
    },
    hideNewProductForm () {
      this.$refs.newProduct.hide()
    },
    async createNewProduct (data) {
      try {
        if (data.collaborator?.id) data.collaborator = data.collaborator.id
        const response = await createProduct(data)
        this.hideNewProductForm()
        successModal('Product is succesvol aangemaakt.')
        this.$router.push({ name: 'ShopBusinessCard', params: { productId: response.data.id } })
        return response
      } catch (error) {
        this.$formulate.handle(error, 'createProduct')
      }
    }
  }
}
</script>
