import { httpClient } from './axiosClients'
import { commaSerializer } from './paramSerializers'

export function getCategories (params) {
  return httpClient.get('/api/shop/category', { params })
}

export function getProducts (payload = {}) {
  // We only fetch the products that have active collaborators.
  const { url = '/api/shop/product?active_only=1', params } = payload
  return httpClient.get(url, { params })
}

export function createOrderLine (payload) {
  return httpClient.post('/api/shop/orderline', payload)
}

export function updateOrderLine (orderlineId, payload) {
  return httpClient.patch(`/api/shop/orderline/${orderlineId}`, payload)
}

export function removeOrderLine (orderlineId) {
  return httpClient.delete(`/api/shop/orderline/${orderlineId}`)
}

export function getCart () {
  return httpClient.get('/api/shop/cart')
}

export function checkout (payload) {
  return httpClient.post('/api/shop/cart/checkout', payload)
}

export function getOrders (payload = {}) {
  const { url = '/api/shop/order', params } = payload
  return httpClient.get(url, { params })
}

export function getOrderDetails (orderId) {
  return httpClient.get(`/api/shop/order/${orderId}`)
}

export function getProductDetails (productId) {
  return httpClient.get(`/api/shop/products/${productId}`)
}

export function deleteProduct (productId) {
  return httpClient.delete(`/api/shop/products/${productId}`)
}

export function getCollaboratorPrintData (collaboratorId, params) {
  return httpClient.get(`/api/collaborator/${collaboratorId}/print-data`, {
    params,
    paramsSerializer: commaSerializer
  })
}

export function storeGeneratedBusinessCard (productId, payload) {
  return httpClient.post(`/api/shop/product/${productId}/store-design`, payload)
}

export function fetchProductCreationData () {
  return httpClient.get('/api/shop/products/creation-data')
}

export function createProduct (payload) {
  return httpClient.post('/api/shop/product', payload)
}
